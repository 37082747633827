@use "definitions" as *;


.body__content {
  .banner {
    margin-top: calc(var(--spacing2) * -1);
    margin-bottom: var(--spacing2);
    width: 100%;
    margin-left: calc(var(--spacing5) * -1);
    box-shadow: 0 0 var(--spacing0) var(--spacing0) var(--shadow-gray);
    padding: var(--spacing2) var(--spacing5);
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .banner-item--right {
    margin-left: auto;
    order: 2;
  }
}


.icon--warning {
  color: $warning-color;
}
