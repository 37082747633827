@use "definitions" as *;

.mod-rules__form-wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 50vh;
  margin: 3px -1px 3px 0;
  border: 1px solid lg-color(--gray-color-20);
  border-radius: var(--border-radius-small);
}

.mod-rules__form {
  flex-grow: 1;
  max-height: 100%;
  overflow: hidden;
}

.mod-rules__form-inner {
  padding: var(--spacing1) var(--spacing2);
  box-sizing: border-box;
}

.mod-rules__form-buttons {
  padding: var(--spacing1) var(--spacing2);
  border-top: 1px solid lg-color(--gray-color-20);
}

.form-row.form-row--tag-selector .form-row__label {
  line-height: var(--spacing5) + 2;
}
