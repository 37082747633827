.table__row--selected {
    background-color: lg-color(--base-10);
}


.settings__side-control__row {
    line-height: 20px;
    margin-bottom: var(--spacing2);
}

.settings__side-control__row--stats {
    display: flex;
    justify-content: space-between;
}

.settings__side-control__row__counter {
    display: flex;

    > lg-status-icon {
        padding-left: var(--spacing2);
    }
}

.settings__scrollable {
    height: 100%;
}
